import { cleanUpTracking } from './tracking'
import { emitEvent } from './utils'

export const findForms = (): HTMLFormElement[] => {
  const forms = document.querySelectorAll<HTMLFormElement>('form[method="POST"]')

  // List of allowed action URLs
  const allowedUrls = ['n8n.fuchs-eule.de', 'hooks.zapier.com']

  const trackedForms = Array.from(forms)
    .filter((form: HTMLFormElement) => {
      const actionUrl = form.getAttribute('action')
      return actionUrl && allowedUrls.some(url => actionUrl.includes(url))
    })

  return trackedForms
}

const observeFormVisibilityMutation = (targetNode: HTMLElement, callback: () => void): void => {
  const observer = new MutationObserver((mutationsList) => {
    if (mutationsList[0].type === 'attributes' && mutationsList[0].attributeName === 'style') {
      const element = mutationsList[0].target as HTMLElement
      if (window.getComputedStyle(element).display === 'block') {
        callback()

        // Disconnect the observer to stop further observation
        observer.disconnect()
      }
    }
  })

  // Configuration of the observer:
  const observerConfig = {
    attributes: true,
    attributeFilter: ['style'],
    childList: false,
    subtree: false,
  }

  observer.observe(targetNode, observerConfig)
}

export const observeFormStepChange = (forms: HTMLFormElement[]): void => {
  forms.forEach((form) => {
    const formSteps = form.querySelectorAll('[if-step]')

    formSteps.forEach((step) => {
      const targetNode = step as HTMLElement

      observeFormVisibilityMutation(targetNode, () => {
        emitEvent('webflowFormStepChange', { step: targetNode.getAttribute('if-step') })
      })
    })
  })
}

export const observeFormSubmission = (): void => {
  const targetNodes = document.querySelectorAll('.w-form-done')

  targetNodes.forEach((targetNode) => {
    observeFormVisibilityMutation(targetNode as HTMLElement, () => {
      emitEvent('webflowFormSubmit')
      cleanUpTracking()
    })
  })
}
