// Extend the Window interface to include dataLayer
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[]
  }
}

// Push information to the dataLayer (Google Tag Manager)
export const pushToDataLayer = (params: object): void => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push(params)
  }
}

// Emit a custom event and also push it to the dataLayer
export const emitEvent = (eventName: string, detail?: object): void => {
  const event = new CustomEvent(eventName, detail)
  document.dispatchEvent(event)
  pushToDataLayer({ ...detail, ...{ event: event.type } })
}

// Persist filtered url parameters in sessionStorage
export const persistInSessionStorage = (filter: (key: string) => boolean): void => {
  const urlParams = new URLSearchParams(window.location.search)
  const params: Record<string, string> = Array.from(urlParams.entries())
    .filter(([key]) => filter(key))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  Object.entries(params).forEach(([key, value]) => {
    sessionStorage.setItem(key, value as string)
  })
}

// Clean up sessionStorage based on a filter
export const cleanUpSessionStorage = (filter: (key: string) => boolean): void => {
  Object.keys(sessionStorage)
    .filter(filter)
    .forEach(key => sessionStorage.removeItem(key))
}
