import { persistTracking, populateDataLayer, populateFormFields } from './tracking'
import { findForms, observeFormStepChange, observeFormSubmission } from './observer'

// Handle tracking parameters and attach observers
persistTracking()
populateDataLayer()
document.addEventListener('DOMContentLoaded', () => {
  const forms = findForms()
  populateFormFields(forms)
  observeFormSubmission()
  observeFormStepChange(forms)
})
